
import LoggedOut from "@/components/views/loggedOut/LoggedOut.vue";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  name: "resetPassword",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    LoggedOut,
  },
})
export default class ResetPassword extends Vue {
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  key = "";
  username = "";
  password = "";
  validatePassword = "";
  tooltip =
    "&#x2022; Password should be 8 - 32 characters long<br>" +
    "&#x2022; Password should contain at least one uppercase and one lowercase character<br>" +
    "&#x2022; Password should contain at least one number<br>" +
    "&#x2022; Password should contain at least one special character";

  newPasswordLabelIcon = {
    iconClass: "icon-info",
    tooltipText: this.tooltip,
  };

  get shouldDisableSubmitBtn() {
    return this.errors.any() || !this.username || !this.password || !this.validatePassword;
  }

  onEnterPress() {
    this.resetPassword();
  }

  toForgotPassword() {
    this.$router.push({ path: "/forgot-password" });
  }

  async resetPassword() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.showAppLoadingMask();
    try {
      await this.$jfUsers.resetPassword({
        username: this.username,
        newPassword: this.password,
        key: this.key,
      });
      this.$jfNotification.success({ text: this.$jfMessages.password_reset_success });
      this.hideAppLoadingMask();
      this.$router.push({ path: "/login" });
    } catch (e) {
      this.$log.error(e);
      this.hideAppLoadingMask();
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      let notificationClasses = [];
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.resetPassword.badPolicy) {
          // when receiving bad policy errors the RestClientError's httpBody Object will contain a errors array that we need to display in list
          errorMessage = this.errorsToHTMLList(e.httpBody.errors) || e.httpBody.message;
          notificationClasses.push("text-left");
        } else if (e.httpBody.result === storeErrorsMapping.resetPassword.tokenUsed) {
          errorMessage = this.$jfMessages.reset_password_failed_link_not_valid;
        } else {
          // can be 'invalid credentials' or other rest client error
          errorMessage = this.$jfMessages.generateMailLink(e.httpBody.message || (e.httpStatusText as string));
        }
      }
      this.$jfNotification.error({
        title: "Error",
        text: errorMessage,
        data: { textWrapperClasses: notificationClasses },
      });

      if (errorMessage === this.$jfMessages.reset_password_failed_link_not_valid) {
        this.toForgotPassword();
      }
    }
  }

  errorsToHTMLList(errors?: string[]): string | null {
    return errors ? errors.map(error => `<div mb-2>&#x2022; ${error}.</div>`).join("") : null;
  }

  created() {
    this.key = this.$route.query.key as string;
  }

  mounted() {
    ((this.$refs["reset-pwd-username"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }
}
